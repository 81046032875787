<template>
    <span class="font-semibold py-0.5 rounded-lg whitespace-nowrap" :class="divClass">
        <slot />
    </span>
</template>

<script setup>
import { computed } from "vue";

const props = defineProps({
    color: {
        type: String,
        default: "theme",
    },
    dark: {
        type: Boolean,
        default: false,
    },
    size: {
        type: String,
        default: "sm",
    },
});

const divClass = computed(() => {
    return {
        [setSize()]: true,
        [setPadding()]: true,
        [setTextColor()]: true,
        [setBgColor()]: true,
    };
});

const setSize = () => {
    if (props.size == "3xs") return "text-3xs";
    else if (props.size == "2xs") return "text-2xs";
    else if (props.size == "xs") return "text-xs";
    else if (props.size == "sm") return "text-sm";
    else if (props.size == "lg") return "text-lg";
    else if (props.size == "xl") return "text-xl";
    else if (props.size == "2xl") return "text-2xl";
    else if (props.size == "3xl") return "text-3xl";
    else if (props.size == "4xl") return "text-4xl";
    else if (props.size == "5xl") return "text-5xl";
    else if (props.size == "6xl") return "text-6xl";
    else if (props.size == "7xl") return "text-7xl";
    else if (props.size == "8xl") return "text-8xl";
    else if (props.size == "9xl") return "text-9xl";
};

const setPadding = () => {
    let p = "px-1.5";
    if (props.size == "2xs") {
        p = "px-1";
    } else if (props.size == "3xs") {
        p = "px-0.5";
    }
    return p
};

const setTextColor = () => {
    if (props.color == "gray") return props.dark ? "text-gray-100" : "text-gray-600";
    else if (props.color == "red") return props.dark ? "text-red-100" : "text-red-700";
    else if (props.color == "orange") return props.dark ? "text-orange-100" : "text-orange-700";
    else if (props.color == "yellow") return props.dark ? "text-yellow-100" : "text-yellow-700";
    else if (props.color == "green") return props.dark ? "text-green-100" : "text-green-700";
    else if (props.color == "blue") return props.dark ? "text-blue-100" : "text-blue-700";
    else if (props.color == "pink") return props.dark ? "text-pink-100" : "text-pink-700";
    else if (props.color == "cyan") return props.dark ? "text-cyan-100" : "text-cyan-700";
    else if (props.color == "theme") return props.dark ? "text-theme-100" : "text-theme-700";
    else if (props.color == "white") return props.dark ? "text-gray-800" : "text-gray-800";
    else if (props.color == "black") return props.dark ? "text-white" : "text-white";
};

const setBgColor = () => {
    if (props.color == "gray") return props.dark ? "bg-gray-700" : "bg-gray-200";
    else if (props.color == "red") return props.dark ? "bg-red-500" : "bg-red-100";
    else if (props.color == "orange") return props.dark ? "bg-orange-500" : "bg-orange-100";
    else if (props.color == "yellow") return props.dark ? "bg-yellow-500" : "bg-yellow-100";
    else if (props.color == "green") return props.dark ? "bg-green-500" : "bg-green-100";
    else if (props.color == "blue") return props.dark ? "bg-blue-500" : "bg-blue-100";
    else if (props.color == "pink") return props.dark ? "bg-pink-500" : "bg-pink-100";
    else if (props.color == "cyan") return props.dark ? "bg-cyan-500" : "bg-cyan-100";
    else if (props.color == "theme") return props.dark ? "bg-theme-500" : "bg-theme-100";
    else if (props.color == "white") return props.dark ? "bg-white" : "bg-white";
    else if (props.color == "black") return props.dark ? "bg-black" : "bg-black";
};
</script>
